<template>
  <div>
    <nav-bar></nav-bar>
    <tool-box></tool-box>

    <div class="container mt-2 mb-2">
      <!-- 试卷信息 -->
      <div class="paper">
        <div class="paper-name">{{ currentPaper.title }}</div>
        <div class="paper-info">
          <div>类型：{{ toSwitchPaperType(currentPaper.examtype_id) }}</div>
          <div>总分：{{ currentPaper.total_score }}分</div>
          <!-- <div>时间：{{ currentPaper.time }}分钟</div> -->
          <div>题量：{{ currentPaper.question_num }}道</div>
        </div>
      </div>
      <!-- 试题及答题卡 -->
      <el-row>
        <!-- 试题 -->
        <el-col :span="18">
          <div id="test" class="question">
            <div class="question-category" v-for="(item,index) in displayList" :key="index">
              <!-- 类别名 -->
              <div 
								class="category-name" 
								v-if="item.test.length || item.sub.length">
								<div v-html="item.title"></div>
							</div>
              <!-- 类别下的试题 -->
              <div class="category-question" v-if="item.test && item.test.length">
                <div class="category-question-item" v-for="(citem, cindex) in item.test" :key="cindex" :id="citem.id">
                  <!-- 非综合体 -->
                  <div class="item-title">
                    <div>{{ cindex + 1 }}、</div>
                    <div class="item-title-content" v-html="citem.title"></div>
                  </div>

                  <!-- 单选 -->
                  <div v-if="citem.type == 1">
                    <el-radio-group class="item-option" v-model="citem.chooseAnswer">
                      <el-radio :label="option.label" v-for="option in citem.optionList" :key="option.label">
                        <div class="item-option-item">
                          <span>{{ option.label }}.</span>
                          <div v-html="option.value"></div>
                        </div>
                      </el-radio>
                    </el-radio-group>
                  </div>

                  <!-- 多选 -->
                  <div v-if="citem.type == 2">
                    <el-checkbox-group class="item-option" v-model="citem.chooseAnswer">
                      <el-checkbox :label="option.label" v-for="option in citem.optionList" :key="option.label">
                        <div class="item-option-item">
                          <span>{{ option.label }}.&nbsp;&nbsp;</span>
                          <div v-html="option.value"></div>
                        </div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>

                  <!-- 判断 -->
                  <div v-if="citem.type == 3">
                    <el-radio-group class="item-option" v-model="citem.chooseAnswer">
                      <el-radio label="1">
                        <div class="item-option-item"><span>A.</span><div>对</div></div>
                      </el-radio>
                      <el-radio label="0">
                        <div class="item-option-item"><span>B.</span><div>错</div></div>
                      </el-radio>
                    </el-radio-group>
                  </div>

                  <!-- 题脚 -->
                  <div class="item-footer">
                    <el-tooltip v-if="!citem.analysisFlag" effect="dark" content="查看解析" placement="top">
                      <el-button type="primary" circle plain size="small" icon="el-icon-zoom-in"
                        @click="doChangeAnalysis(citem, item.id, true)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-else effect="dark" content="隐藏解析" placement="top">
                      <el-button type="warning" circle plain size="small" icon="el-icon-zoom-out"
                        @click="doChangeAnalysis(citem, item.id, false)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="citem.fav == 0" effect="dark" content="收藏" placement="top">
                      <el-button type="primary" circle size="small" icon="el-icon-star-off"
                        @click="doCollectTest(citem, item.id)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-else effect="dark" content="取消收藏" placement="top">
                      <el-button type="warning" circle size="small" icon="el-icon-star-on"
                        @click="doCancleCollectTest(citem, item.id)"></el-button>
                    </el-tooltip>
                  </div>
                  <!-- 试题解析 -->
                  <div class="item-analysis" v-if="citem.analysisFlag">
										<!-- 单选、多选、判断 -->
										<div v-if="(citem.type==1||citem.type==2||citem.type ==3)">
											<div class="item-analysis-choose item-analysis-list">
											  <div class="label">您的答案：</div>
												<!-- 判断题显示 -->
												<div v-if='citem.type==3'>
													<div class="value">
														<span v-if='citem.chooseAnswer==1'>对</span>
														<span v-if='citem.chooseAnswer==0'>错</span>
													</div>
												</div>
												<div v-else>
													 <div class="value" v-html="citem.chooseAnswer"></div>
												</div>
											</div>
											<div class="item-analysis-answer item-analysis-list">
											  <div class="label">正确答案：</div>
												<!-- 判断题显示 -->
												<div v-if='citem.type==3'>
													<span v-if='citem.answer==1'>对</span>
													<span v-if='citem.answer==0'>错</span>
												</div>
												<div v-else>
													 <div class="value" v-html="citem.answer"></div>
												</div>
											</div>
											<div class="item-analysis-info item-analysis-list">
											  <div class="label">试题解析：</div>
											  <div class="value" v-html="citem.analysis"></div>
											</div>
										</div>
										<!-- 填空、综合、简答 -->
										<div v-if="(citem.type==4 ||citem.type==6)">
											<div class="item-analysis-answer item-analysis-list">
											  <div class="label">正确答案：</div>
											  <div class="value" v-html="citem.answer"></div>
											</div>
											<div class="item-analysis-info item-analysis-list">
											  <div class="label">试题解析：</div>
											  <div class="value" v-html="citem.analysis"></div>
											</div>
										</div>
                  </div>
                </div>
              </div>
              <!-- 类别下的试题 -->
              <div class="category-question" v-if="item.sub.length">
                <div class="category-question-item" v-for="(citem, cindex) in item.sub" :key="cindex" :id="citem.id">
                  <!-- 题目 -->
                  <!-- 综合体需要大写数字 -->
                  <div class="item-title">
                    <div>{{ toSwitchNumberUpperCase(cindex + 1) }}、</div>
                    <div class="item-title-content" v-html="citem.title"></div>
                  </div>

                  <!-- 综合体 -->
                  <div>
                    <div class="type-five-item" v-for="(innerTest, innerTestIndex) in citem.testList" :key="innerTest.id" :id="innerTest.id">
                      <!-- 题目 -->
                      <div class="item-title">
                        <div>{{ innerTestIndex + 1 }}、</div><div v-html="innerTest.title"></div>
                      </div>
                      <!-- 单选 -->
                      <div v-if="innerTest.type == 1">
                        <el-radio-group class="item-option" v-model="innerTest.chooseAnswer">
                          <el-radio :label="option.label" v-for="option in innerTest.optionList" :key="option.label">
                            <div class="item-option-item">
                              <span>{{ option.label }}.</span>
                              <div v-html="option.value"></div>
                            </div>
                          </el-radio>
                        </el-radio-group>
                      </div>
                      <!-- 多选 -->
                      <div v-if="innerTest.type == 2">
                        <el-checkbox-group class="item-option" v-model="innerTest.chooseAnswer">
                          <el-checkbox :label="option.label" v-for="option in innerTest.optionList" :key="option.label">
                            <div class="item-option-item">
                              <span>{{ option.label }}.&nbsp;&nbsp;</span>
                              <div v-html="option.value"></div>
                            </div>
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>
                      <!-- 判断 -->
                      <div v-if="innerTest.type == 3">
                        <el-radio-group class="item-option" v-model="innerTest.chooseAnswer">
                          <el-radio label="1">
                            <div class="item-option-item"><span>A.</span><div>对</div></div>
                          </el-radio>
                          <el-radio label="0">
                            <div class="item-option-item"><span>B.</span><div>错</div></div>
                          </el-radio>
                        </el-radio-group>
                      </div>
                      <!-- 题脚 -->
                      <div class="item-footer">
                        <el-tooltip v-if="!innerTest.analysisFlag" effect="dark" content="查看解析" placement="top">
                          <el-button type="primary" circle plain size="small" icon="el-icon-zoom-in"
                            @click="doChangeAnalysis(innerTest, item.id, true)"></el-button>
                        </el-tooltip>
                        <el-tooltip v-else effect="dark" content="隐藏解析" placement="top">
                          <el-button type="warning" circle plain size="small" icon="el-icon-zoom-out"
                            @click="doChangeAnalysis(innerTest, item.id, false)"></el-button>
                        </el-tooltip>
                        <el-tooltip v-if="innerTest.fav == 0" effect="dark" content="收藏" placement="top">
                          <el-button type="primary" circle size="small" icon="el-icon-star-off"
                            @click="doCollectTest(innerTest, item.id)"></el-button>
                        </el-tooltip>
                        <el-tooltip v-else effect="dark" content="取消收藏" placement="top">
                          <el-button type="warning" circle size="small" icon="el-icon-star-on"
                            @click="doCancleCollectTest(innerTest, item.id)"></el-button>
                        </el-tooltip>
                      </div>
                      <!-- 试题解析 -->
											<div class="item-analysis" v-if="innerTest.analysisFlag">
												<!-- 单选、多选、判断 -->
												<div v-if="(innerTest.type==1||innerTest.type==2||innerTest.type ==3)">
													<div class="item-analysis-choose item-analysis-list">
													  <div class="label">您的答案：</div>
														<!-- 判断题显示 -->
														<div v-if='innerTest.type==3'>
															<div class="value">
																<span v-if='innerTest.chooseAnswer==1'>对</span>
																<span v-if='innerTest.chooseAnswer==0'>错</span>
															</div>
														</div>
														<div v-else>
															 <div class="value" v-html="innerTest.chooseAnswer"></div>
														</div>
													</div>
													<div class="item-analysis-answer item-analysis-list">
													  <div class="label">正确答案：</div>
														<!-- 判断题显示 -->
														<div v-if='innerTest.type==3'>
															<div class="value">
																<span v-if='innerTest.answer==1'>对</span>
																<span v-if='innerTest.answer==0'>错</span>
															</div>
														</div>
														<div v-else>
															 <div class="value" v-html="innerTest.answer"></div>
														</div>
													</div>
													<div class="item-analysis-info item-analysis-list">
													  <div class="label">试题解析：</div>
													  <div class="value" v-html="innerTest.analysis"></div>
													</div>
												</div>
												<!-- 填空、综合、简答 -->
												<div v-if="(innerTest.type==4 ||innerTest.type==6)">
													<div class="item-analysis-answer item-analysis-list">
													  <div class="label">正确答案：</div>
													  <div class="value" v-html="innerTest.answer"></div>
													</div>
													<div class="item-analysis-info item-analysis-list">
													  <div class="label">试题解析：</div>
													  <div class="value" v-html="innerTest.analysis"></div>
													</div>
												</div>
											</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <!-- 答题卡 -->
        <el-col :span="6">
          <el-affix :offset="20">
            <div class="question-card">
              <div class="question-card-header">
                <div class="card-header-title">答题卡</div>
              </div>
              <!-- 题号 -->
              <div class="question-card-content">
                <div class="card-content-item" v-for="(item,index) in testList" :key="index">
                    <div class="item-name" v-html="toSwitchQuestionType(item.type)"></div>
                    <!-- 综合题 -->
                    <div v-if="item.sub && item.sub.length">
                      <ul class="item-option" v-for="(citem, cindex) in item.sub" :key="cindex">
                        <li class="bigNum">
                          <h3><a :href="'#' + citem.id">{{ toSwitchNumberUpperCase(cindex + 1) }}：</a></h3>
                        </li>
                        <li :class="isHaveAnswer(test.chooseAnswer)"
                          v-for="(test, testIndex) in citem.testList" :key="testIndex">
                          <a :href="'#' + test.id">{{ testIndex + 1 }}</a>
                        </li>
						            <div v-if="item.sub.length == 0">无</div>
                      </ul>
                    </div>
                    <!-- 非综合题 -->
                    <ul class="item-option" v-if="item.test && item.test.length">
                      <li 
												:class="isHaveAnswer(citem.chooseAnswer)" 
												v-for="(citem, cindex) in item.test" :key="cindex">
                        <a :href="'#' + citem.id">{{ cindex + 1 }}</a>
                      </li>
					            <div v-if="item.test.length == 0">无</div>
                    </ul>
                  </div>
              </div>
              <div class="question-card-footer">
                <div class="empty">未做</div>
                <div class="yes">已做</div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="question-btn">
              <div 
								style='margin-right:20px;'
								class="question-btn-submit" 
								v-loading.fullscreen.lock="fullscreenLoading" 
								@click="doSubmitTest">提交答案</div>
              <div 
								class="question-btn-result" 
								@click="doOpenResult">上次结果</div>
            </div>
          </el-affix>
        </el-col>
      </el-row>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getPaperTest, doCollectTest, doCancleCollectTest, doSignTest,getWrongTest } from '@/api/exam'
import { getSessionStorage } from '@/utils/auth'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 当前试卷
      currentPaper: {},
      // 试卷类型，1：大纲，2：试卷
      currentPaperType: 2,
      // 试题列表
      testList: [],
      // 页面显示的列表
      displayList: [],
      // 原容器高度
      formerHeight: 0,
      // 循环起始位置
      begin: 0,
      // 当前是第几组数据
      currentIndex: 0,
      // loading
      fullscreenLoading: true, 
    }
  },
  created() {
    this.currentPaper = getSessionStorage('currentPaper')
    this.paperType = this.currentPaper.type == 4 ? 1 : 2
    this.openType = getSessionStorage('openType') ? getSessionStorage('openType') : 0;
    this.getQuestionList()
  },
  mounted() {
	  window.addEventListener('scroll', this.scrollPullDown);
  },
  methods: {
    // 下拉处理数据函数
    processingPullDownData(i,totle,index) {
      // debugger
      let subSum = 0;
      let list = this.testList.slice(index)
      for( let num in list){
        let item = list[num];
        //初始化其他数据
        if(!this.displayList[index]){
          this.displayList[index] = {
            "id": item.id,
            "exam_id": item.exam_id,
            "score": item.score,
            "sub": [],
            "test": [],
            "title": item.title,
            "type": item.type
          }
        }
        if(item.sub && item.sub.length){
          while(i < totle){
            if(item.sub[i]){//有数据——>拿数据放进新数组，添加10个为止
              this.displayList[index].sub[i] = item.sub[i];
              subSum += item.sub[i].test.length
              if(subSum >= 10){
                i++;
                return i;
              }
            }else{//没数据——>下一个数组继续取够10个
              this.currentIndex = ++index;
              totle = totle - i;//下一个数组再取totle - i个即可
              i = 0;
              break;
            }
            i++;
          }
        }else {
          while(i < totle){
            if(item.test[i]){//有数据——>拿数据放进新数组
              this.displayList[index].test[i] = item.test[i];
            }else{//没数据——>下一个数组继续
              this.currentIndex = ++index;
              totle = totle - i;
              i = 0;
              break;
            }
            i++;
          }
        }
      }
      return i;
    },
    //下拉加载试题
    scrollPullDown(e) {
      this.formerHeight = $("#test").height();//容器高度
      let scrollHeight = $("html")[0].scrollTop;//滚动到顶部距离
      if(scrollHeight > this.formerHeight - 1000){
        let i = this.begin;
        let totle = i + 10;//终止循环条件
        let index = this.currentIndex;
        //循环索引记录
        this.begin = this.processingPullDownData(i,totle,index);
      }
    },
    // 获取试题
    getQuestionList() {
      getPaperTest({exam_id: this.currentPaper.id}).then(res => {
        if (res.code == 1) {
          res.data.forEach(item => {
            if(item.sub && item.sub.length){
              item.sub.forEach(test => {
                test = this.doPerfectQuestion(test)
                test.test.forEach(sub => {
                    sub = this.doPerfectQuestion(sub)
                })
                test.testList = test.test
              })
            }else if(item.test && item.test.length){
              item.test.forEach(test => {
                test = this.doPerfectQuestion(test)
              })
            }
          })
          this.testList = res.data
          let i = this.begin;//循环次数记录
          let totle = i + 10;//终止循环条件
          let index = this.currentIndex;//当前是第几组题
          this.begin = this.processingPullDownData(i,totle,index);
          // 试题加载完后，关闭loading
          this.fullscreenLoading = false
        }
      })
    },
		getSimpleText(html){//去掉富文本中的标签，保留文本
			var re1 = new RegExp("<.+?>","g");
			var msg = html.replace(re1,'');
			return msg.trim();
		},
    // 将试题中的字段完善
    doPerfectQuestion(test) {
      // 将试题的类型拼接到题目中
      test.title = this.toSwitchQuestionType(test.type) + test.title
      // 试题中的选项
      test.optionList = this.toIntegrationOption(test)
      // 初始化选项，多选题需要初始化为空数组
      test.chooseAnswer = test.type == 2 ? [] : null
			//答案富文本提取纯文本,单选，多选，判断
			if(test.type==1||test.type==2||test.type==3){
					test.answer = this.getSimpleText(test.answer);
			}
      return test
    },
    // 将题型id转成题型label
    toSwitchQuestionType(typeId) {
      let label = ''
      switch (typeId) {
        case 1:
          label = "【单选题】";
          break
        case 2:
          label = "【多选题】";
          break
        case 3:
          label = "【判断题】";
          break
        case 4:
          label = "【填空题】";
          break
        case 5:
          label = "【综合题】";
          break
        case 6:
          label = "【简答题】";
          break
      }
      return '<span style="display: inline-block; vertical-align: top;">' + label + '</span>'
    },
    // 将试题中的选项整合成集合
    toIntegrationOption(test) {
      let keys = ["a", "b", "c", "d", "e", "f", "g", "h", "i"]
      let options = []
      keys.forEach((key) => {
        if (test["option_" + key] && test["option_" + key].trim()) {
          options.push({
            label: key.toUpperCase(),
            value: test["option_" + key],
          })
        }
      })
      return options;
    },
    // 根据试卷的类型id转出类型label
    toSwitchPaperType(typeId) {
      switch (typeId) {
        case 1:
          return "考点预测";
        case 2:
          return "密押试卷";
        case 3:
          return "每日一练";
        case 4:
          return "章节练习";
        case 5:
          return "往年真题";
        case 6:
          return "全真机考";
        case 7:
          return "模拟试题";
      }
    },
    // 将数字转成大写，只可以转两位数
    toSwitchNumberUpperCase(number) {
      const bigNum = ["十", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]
      if (number <= 10) {
        return bigNum[number]
      } else if (number > 10 && number < 20) {
        return "十" + bigNum[number.toString()[1]]
      } else if (number >= 20) {
        return bigNum[number.toString()[0]] + bigNum[number.toString()[1]]
      } else {
        return null
      }
    },
    // 判断是否有合法的答案
    isHaveAnswer(chooseAnswer) {
      if (Array.isArray(chooseAnswer)) {
        return chooseAnswer.length > 0 ? "haveAnswer" : "";
      } else {
        return chooseAnswer ? "haveAnswer" : "";
      }
    },
    // 收藏试题
    doCollectTest(test, id) {
      doCollectTest({
        test_id: test.id,
        type: this.currentPaperType,
        exam_id: this.currentPaper.id,
      }).then(res => {
        if (res.code == 1) {
          this.testList.forEach(item => {
            if (item.id == id) {
              if (item.sub && item.sub.length) {
                item.sub.forEach(sub => {
                  sub.testList.forEach(ele => {
                    if (ele.id == test.id) {
                      ele.fav = 1
                    }
                  })
                })
              } else {
                item.test.forEach(ele => {
                  if (test.id == ele.id) {
                    ele.fav = 1;
                  }
                })
              }
            }
          })
          this.$message({
            message: "收藏成功",
            type: "success",
          })
        }
      });
    },
    // 取消收藏试题
    doCancleCollectTest(test, id) {
      doCancleCollectTest({
        test_id: test.id,
        type: this.currentPaperType,
        exam_id: this.currentPaper.id,
      }).then(res => {
        if (res.code == 1) {
          this.testList.forEach(item => {
            if (item.id == id) {
              if (item.sub && item.sub.length) {
                item.sub.forEach(sub => {
                  sub.testList.forEach(ele => {
                    if (ele.id == test.id) {
                      ele.fav = 0
                    }
                  })
                })
              } else {
                item.test.forEach(ele => {
                  if (test.id == ele.id) {
                    ele.fav = 0
                  }
                })
              }
            }
          })
          this.$message({
            message: "取消收藏成功",
            type: "success",
          })
        }
      });
    },
    // 提交试卷
    doSubmitTest() {
      this.$confirm("是否确认提交试卷?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 开启loading
        this.fullscreenLoading = true
        const params = []
        this.testList.forEach(item => {
          if(item.sub && item.sub.length){
            item.sub.forEach(test => {
              test.testList.forEach(innerTest => {
                if (innerTest.chooseAnswer || innerTest.chooseAnswer == 0) {
                  let obj = this.toIntegrationSubmitParams(innerTest);
                  if(obj){
                    params.push(obj);
                  }
                }
              })
            })
          }else{
            item.test.forEach(test => {
              if (test.type == 4 || test.type == 6) {
                // 填空、简答不需要提交答案
              } else {
                if (test.chooseAnswer || test.chooseAnswer == 0) {
                  let obj = this.toIntegrationSubmitParams(test);
                  if(obj){
                    params.push(obj);
                  }
                }
              }
            })
          }
        })
        if (params.length == 0) {
          // 关闭loading
          this.fullscreenLoading = false
          this.$message('没有试题可提交')
          return
        }
        doSignTest({str: JSON.stringify(params)}).then(res => {
          if (res.code == 1) {
            // 关闭loading
            this.fullscreenLoading = false
            this.$message({
              message: "提交成功",
              type: "success",
            })
            // 进入结果页面
            this.$router.push('/questionresult')
          }
        })
      }).catch(() => {})
    },
    // 整合提交参数
    toIntegrationSubmitParams(test) {
			//用户答案
      const userAnswer = test.type == 2 ? test.chooseAnswer.sort().join("") : test.chooseAnswer
      if(userAnswer && userAnswer.trim() != ''){
        return {
          test_id: test.id,
          answer: userAnswer,//选择的答案
          flag: test.answer == userAnswer ? 1 : 0,//选择的答案与正确答案做比较
          type: this.currentPaperType,
          exam_id: this.currentPaper.id,
        }
      }else{
        return false;
      }
    },
    // 打开上次做题结果
    doOpenResult() {
      let arr = []
      // 找一下有没有之前的做题记录
      this.testList.forEach(category => {
        if (category.test && category.test.length) {
          category.test.forEach(test => {
            //console.log("test:" + test.user_answer);
            if (test.user_answer) {
              arr.push(test.user_answer)
            }
          })
        } else {
          category.sub.forEach(test => {
            test.testList.forEach(question => {
              //console.log("sub:" + question.user_answer);
              if (question.user_answer) {
                arr.push(question.user_answer)
              }
            })
          })
        }
      })
      if(arr.length == 0) {
        this.$message.error('当前试卷未存在做题记录')
      } else {
        window.open('/questionresult')
      }
    },
    // 展示/隐藏当前试题解析
    doChangeAnalysis(test, id, flag) {
      this.testList.forEach(item => {
        if (item.id == id) {
          if (item.sub && item.sub.length) {
            item.sub.forEach(sub => {
              sub.testList.forEach(ele => {
                if (ele.id == test.id) {
                  ele.analysisFlag = flag
                }
              })
            })
          } else {
            item.test.forEach(ele => {
              if (test.id == ele.id) {
                ele.analysisFlag = flag
              }
            })
          }
        }
      })
    },

  },
}
</script>

<style scoped lang="scss">
// 试卷信息 begin
.paper {
  background-color: $background-color;
  box-shadow: var(--el-box-shadow-light);
  padding: 20px;

  .paper-name {
    width: 100%;
    font-size: 22px;
    text-align: center;
  }
  .paper-info {
    width: 40%;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;

    div {
      width: 134px;
      padding: 10px;
    }
  }
}
// 试卷信息 end

// 试题列表、答题卡
.el-row {
  margin-top: 20px;
}

// 试题列表 begin
.question {
  padding: 10px;

  .question-category {
    // 类别名
    .category-name {
			display: flex;
			flex-direction: row;
			align-items: center;
      font-size:20px;
			font-weight: bold;
    }
    // 类别试题列表
    .category-question {
      .category-question-item {
        background-color: $background-color;
        box-shadow: var(--el-box-shadow-light);
        padding: 10px;
        margin: 15px 0;

        .item-title {
          line-height: 1.5rem;
          font-size: 16px;
          font-weight: 600;
          display: flex;
        }
        .item-option {
          color: #666;
          margin-top: 20px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          .el-radio, .el-checkbox {
            margin-bottom: 15px;
            display: flex;
            align-items: baseline;
          }
          .item-option-item {
            font-weight: 600;
            display: flex;
            align-items: baseline;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
          .item-option-item > span {
            margin-right: 5px;
          }
          .item-option-item > div {
            line-height: 1.4rem;
          }
        }
        .type-five-item {
          padding-left: 20px;
          margin-top: 20px;
        }
        // 试题footer
        .item-footer {
          padding: 10px;
          display: flex;
          justify-content: flex-end;
        }
        // 解析
        .item-analysis {
          background-color: #e8e8e8;
          padding: 10px 20px;
          margin-top: 10px;
          .item-analysis-list {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .label {
              color: $danger-color;
              width: 80px;
            }
            .value {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
// 试题列表 end

// 答题卡 begin
.question-card {
  box-shadow: var(--el-box-shadow-light);
  background-color: $background-color;
  padding: 10px;

  .question-card-header {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #EBEEF5;

    .card-header-title {
      font-size: 16px;
      font-weight: 700;
      padding-left: 10px;
    }
  }
  .question-card-content {
    width: 100%;
    height: 350px;
    margin: 10px 0;
    overflow-y: auto;

    .card-content-item {
      margin-bottom: 10px;

      .item-name {
        color: $danger-color;
      }
      .item-option {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      .item-option > li {
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin: 2px;
        cursor: pointer;
      }
      .item-option > li.haveAnswer {
        background-color: $primary-color;
      }
      .item-option > li.bigNum {
        width: 50px;
        text-align: right;
        border: none;
      }
      .item-option > li a {
        width: 100%;
        display: inline-block;
      }
    }
  }
  .question-card-footer {
    padding: 10px 10px 0;
    display: flex;
  }
  .question-card-footer > div {
    width: 45px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    margin-right: 10px;
  }
  .question-card-footer > div.empty {
    border: 1px solid #ccc;
  }
  .question-card-footer > div.yes {
    color: #fff;
    background-color: $primary-color;
  }
}
// 答题卡 end

// 试卷按钮 begin
.question-btn {
  margin-top: 10px;
  display: flex;

  .question-btn-submit {
    background-color: $danger-color;
  }
  .question-btn-result {
    background-color: $primary-color;
  }
}
.question-btn > div {
  color: #fff;
  flex: 1;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.question-btn > div:hover {
  background-color: $theme-color;
}
// 试卷按钮 end
</style>

<style>
/* 将试题中的图片变为块标签 */
.item-title-content img {
  display: block;
}
</style>
